html {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  height: 100vh;
  font-family: "Genos", sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(227, 242, 252);
  flex-grow: 1;
  height: 100vh;
}

.view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem;
}

.title {
  font-size: 5rem;
  padding-bottom: 5rem;
  font-family: "Genos", sans-serif;
}

.points-bar {
  display: flex;
  flex-direction: row;
  height: 5rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(98, 107, 119);
  border-bottom: solid 3px rgb(71, 88, 165);
  margin-bottom: 2rem;
  box-shadow: 0 0 10px #000;
}

.points {
  font-size: 3rem;
  font-weight: bold;
  color: rgb(120, 168, 223);
  padding-right: 4rem;
  font-family: "Genos", sans-serif;
}

.big-x {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30rem;
  font-weight: bold;
  text-shadow: 0 0 10px #fff;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: wait;
}

.color-name {
  height: 30vmin;
  font-size: 10rem;
  font-family: "Genos", sans-serif;
}

.buttons {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

.button {
  border: none;
  color: lightsteelblue;
  background-color: teal;
  padding: 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 3rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  font-family: "Genos", sans-serif;
}

.button-color {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10rem;
  margin: 4px 2px;
  cursor: pointer;
  width: 20rem;
  height: 20rem;
  border-radius: 4rem;
  font-family: "Genos", sans-serif;
}
